// import OpenNotification from "utilities/ToastNotification";

// const { createSlice } = require("@reduxjs/toolkit");
// const InitialState = {
//   cartProduct: [],
//   coupon_disc: 0,
//   coupon_code: "",
//   api_coupon_disc: 0,
//   api_coupon_code: "",
//   offer_name: "",
//   percentage_value: 0,
//   expected_date: new Date(),
//   expected_amount: 0,
//   selected_coupon: [],
// };
// const cartSlice = createSlice({
//   name: "customer",
//   initialState: InitialState,
//   reducers: {
//     change_quantity: (state, action) => {
//       const productToAdd = action.payload;
//       const index = state.cartProduct.findIndex(
//         (product) => product.id == productToAdd.id
//       );
//       if (index != -1) {
//         if (state.cartProduct[index]?.item_offer != null) {
//           const data = state.cartProduct[index]?.item_offer?.split("|");
//           const indices = [];
//           for (let i = 0; i < data.length; i += 2) {
//             indices.push(parseInt(data[i]));
//           }
//           const percentages = [];
//           for (let i = 1; i < data.length; i += 2) {
//             percentages.push(parseFloat(data[i]).toFixed(2));
//           }
//           const disc_in_percetge =
//             productToAdd.quantity - 1 >=
//             state.cartProduct[index]?.percentages_dic?.length
//               ? state.cartProduct[index].percentages_dic[
//                   state.cartProduct[index].percentages_dic.length - 1
//                 ]
//               : state.cartProduct[index].percentages_dic[
//                   productToAdd.quantity - 1
//                 ];
//           const disc_amt =
//             state.cartProduct[index]?.discount_type == "P"
//               ? (productToAdd?.mrp_price *
//                   state.cartProduct[index]?.fixed_item_discount) /
//                 100
//               : state.cartProduct[index]?.fixed_item_discount;
//           const disc_cash =
//             (state.cartProduct[index].price * disc_in_percetge) / 100;

//           state.cartProduct[index].quantity = productToAdd.quantity;
//           state.cartProduct[index].discount_cash =
//             productToAdd.quantity * disc_amt;
//           state.cartProduct[index].used_disc_cash =
//             productToAdd.quantity * disc_cash;
//           state.cartProduct[index].mrp_price =
//             state.cartProduct[index].price - disc_cash;
//         } else {
//           // const qty = parseInt(state.cartProduct[index].quantity) + 1;
//           // const disc_cash = state.cartProduct[index]?.discount_type == 'P' ? (state.cartProduct[index].price * state.cartProduct[index].item_discount / 100) : state.cartProduct[index].item_discount;
//           // state.cartProduct[index].quantity++;
//           // state.cartProduct[index].discount_cash = qty * disc_cash
//           // state.cartProduct[index].used_disc_cash = qty * disc_cash

//           // state.cartProduct[index].mrp_price = (state.cartProduct[index].price - disc_cash)

//           const disc_cash =
//             state.cartProduct[index]?.discount_type == "P"
//               ? (state.cartProduct[index].price *
//                   state.cartProduct[index].item_discount) /
//                 100
//               : state.cartProduct[index].item_discount;
//           state.cartProduct[index].quantity = productToAdd.quantity;
//           state.cartProduct[index].discount_cash =
//             productToAdd.quantity * disc_cash;
//           state.cartProduct[index].used_disc_cash =
//             productToAdd.quantity * disc_cash;
//         }
//       }
//     },
//     update_price: (state, action) => {
//       const productToAdd = action.payload;

//       const index = state.cartProduct.findIndex(
//         (product) => product.id == productToAdd.id
//       );

//       state.cartProduct[index].fixed_price = productToAdd.price;
//       state.cartProduct[index].price = productToAdd.price;
//     },
//     setHDdate: (state, action) => {
//       state.expected_date = action.payload;
//     },
//     setHDExpectedamt: (state, action) => {
//       state.expected_amount = parseInt(action.payload);
//     },
//     setselectedCoupon: (state, action) => {
//       state.selected_coupon = action.payload;
//     },
//     addCart: (state, action) => {
//       const productToAdd = action.payload;
//       const index = state.cartProduct.findIndex(
//         (product) => product.id == productToAdd.id
//       );

//       if (index === -1) {
//         // Product doesn't exist in cart, add it with quantity 1
//         console.log("log1");
//         if (productToAdd?.item_offer != null) {
//           const disc_amt =
//             productToAdd?.discount_type == "P"
//               ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
//               : productToAdd.item_discount;

//           const data = productToAdd?.item_offer?.split("|");
//           const indices = [];
//           for (let i = 0; i < data.length; i += 2) {
//             indices.push(parseInt(data[i]));
//           }

//           // Extracting percentages
//           const percentages = [];
//           for (let i = 1; i < data.length; i += 2) {
//             // percentages.push(parseInt(data[i]));
//             percentages.push(parseFloat(data[i]).toFixed(2));
//           }
//           let price = parseFloat(productToAdd.mrp_price - disc_amt);
//           const disc_cash = (price * percentages[0]) / 100;
//           const total_before_gst =
//             productToAdd.mrp_price - disc_amt - disc_cash; // Example: 20000
//           const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

//           // Calculate GST amount (percentage of total_before_gst)
//           const gst_amount = (total_before_gst * gst_tax) / 100;

//           // Calculate total after GST
//           const total_after_gst = total_before_gst + gst_amount;
//           state.cartProduct = [
//             {
//               ...productToAdd,
//               price: productToAdd.mrp_price - disc_amt,
//               quantity: 1,
//               discount_cash: disc_amt,
//               used_disc_cash: disc_cash,
//               mrp_price: productToAdd.mrp_price - disc_amt - disc_cash,
//               percentages_dic: percentages,
//               item_discount: percentages[0],
//               fixed_price: productToAdd.mrp_price,
//               fixed_item_discount: productToAdd.item_discount,
//               discount_type: productToAdd?.discount_type,
//               total_after_gst: total_after_gst,
//             },
//             ...state.cartProduct,
//           ];
//         } else {
//           const disc_cash =
//             productToAdd?.discount_type == "P"
//               ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
//               : productToAdd.item_discount;
//           const total_before_gst = productToAdd.mrp_price - disc_cash; // Example: 20000
//           const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

//           // Calculate GST amount (percentage of total_before_gst)
//           const gst_amount = (total_before_gst * gst_tax) / 100;

//           // Calculate total after GST
//           const total_after_gst = total_before_gst + gst_amount;
//           state.cartProduct = [
//             {
//               ...productToAdd,
//               price: productToAdd.mrp_price,
//               quantity: 1,
//               discount_cash: disc_cash,
//               used_disc_cash: disc_cash,
//               mrp_price: productToAdd.mrp_price - disc_cash,
//               fixed_price: productToAdd.mrp_price,
//               fixed_item_discount: productToAdd.item_discount,
//               total_after_gst: total_after_gst,
//             },
//             ...state.cartProduct,
//           ];
//         }
//       } else {
//         // Product already exists in cart, increase quantity by 1

//         if (!state.cartProduct[index].selected_product) {
//           if (
//             parseInt(state.cartProduct[index].quantity) ==
//             parseInt(state.cartProduct[index].stock_qty)
//           ) {
//             OpenNotification(
//               "danger",
//               <p>
//                 <span style={{ fontWeight: 600, color: "red" }}>
//                   Out of stock!!
//                 </span>
//                 <br />
//                 <span>
//                   Available Quantity: {state.cartProduct[index].stock_qty}
//                 </span>
//               </p>
//             );
//           } else {
//             if (productToAdd?.item_offer != null) {
//               console.log("log5");

//               const data = productToAdd?.item_offer?.split("|");
//               const indices = [];
//               for (let i = 0; i < data.length; i += 2) {
//                 indices.push(parseInt(data[i]));
//               }

//               // Extracting percentages
//               const percentages = [];
//               for (let i = 1; i < data.length; i += 2) {
//                 // percentages.push(parseInt(data[i]));
//                 percentages.push(parseFloat(data[i]).toFixed(2));
//               }

//               const disc_amt =
//                 productToAdd?.discount_type == "P"
//                   ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
//                   : productToAdd.item_discount;

//               const qty = parseInt(state.cartProduct[index].quantity) + 1;
//               const disc_in_percetge =
//                 qty - 1 >= state.cartProduct[index]?.percentages_dic?.length
//                   ? state.cartProduct[index].percentages_dic[
//                       state.cartProduct[index].percentages_dic.length - 1
//                     ]
//                   : state.cartProduct[index].percentages_dic[qty - 1];
//               const disc_cash =
//                 (state.cartProduct[index].price * disc_in_percetge) / 100;

//               state.cartProduct[index].quantity++;
//               state.cartProduct[index].discount_cash = qty * disc_amt;
//               state.cartProduct[index].item_discount = disc_in_percetge;
//               state.cartProduct[index].used_disc_cash = qty * disc_cash;
//               state.cartProduct[index].total_after_gst =
//                 state.cartProduct[index].total_after_gst * qty;

//               state.cartProduct[index].mrp_price =
//                 state.cartProduct[index].price - disc_cash;
//             } else {
//               const qty = parseInt(state.cartProduct[index].quantity) + 1;

//               const disc_cash =
//                 state.cartProduct[index]?.discount_type == "P"
//                   ? (state.cartProduct[index].price *
//                       state.cartProduct[index].item_discount) /
//                     100
//                   : state.cartProduct[index].item_discount;

//               const total_before_gst =
//                 (productToAdd.mrp_price - disc_cash) * qty;

//               const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

//               // Calculate GST amount (percentage of total_before_gst)
//               const gst_amount = (total_before_gst * gst_tax) / 100;

//               // Calculate total after GST
//               const total_after_gst = total_before_gst + gst_amount;
//               state.cartProduct[index].quantity++;
//               state.cartProduct[index].discount_cash = qty * disc_cash;
//               state.cartProduct[index].used_disc_cash = qty * disc_cash;
//               state.cartProduct[index].total_after_gst = total_after_gst;
//               state.cartProduct[index].mrp_price =
//                 state.cartProduct[index].price - disc_cash;
//             }
//           }
//         } else {
//           if (productToAdd?.item_offer != null) {
//             console.log("log3");

//             const data = productToAdd?.item_offer?.split("|");
//             const indices = [];
//             for (let i = 0; i < data.length; i += 2) {
//               indices.push(parseInt(data[i]));
//             }

//             // Extracting percentages
//             const percentages = [];
//             for (let i = 1; i < data.length; i += 2) {
//               // percentages.push(parseInt(data[i]));
//               percentages.push(parseFloat(data[i]).toFixed(2));
//             }

//             const disc_cash = (productToAdd.mrp_price * percentages[0]) / 100;
//             const total_before_gst = productToAdd.mrp_price - disc_cash; // Example: 20000
//             const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

//             // Calculate GST amount (percentage of total_before_gst)
//             const gst_amount = (total_before_gst * gst_tax) / 100;

//             // Calculate total after GST
//             const total_after_gst = total_before_gst + gst_amount;
//             state.cartProduct = [
//               {
//                 ...productToAdd,
//                 price: productToAdd.mrp_price,
//                 quantity: 1,
//                 discount_cash: disc_cash,
//                 mrp_price: productToAdd.mrp_price - disc_cash,
//                 percentages_dic: percentages,
//                 item_discount: percentages[0],
//                 total_after_gst: total_after_gst,
//                 discount_type: "P",
//               },
//               ...state.cartProduct,
//             ];
//           } else {
//             // const disc_cash = productToAdd?.discount_type == 'P' ? (productToAdd.mrp_price * productToAdd.item_discount / 100) : productToAdd.item_discount;
//             // state.cartProduct = [
//             //     {
//             //         ...productToAdd,
//             //         price: productToAdd.mrp_price,
//             //         quantity: 1,
//             //         discount_cash: disc_cash,
//             //         mrp_price: productToAdd.mrp_price - disc_cash

//             //     },
//             //     ...state.cartProduct
//             // ];
//             console.log("log2");
//             const disc_cash =
//               productToAdd?.discount_type == "P"
//                 ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
//                 : productToAdd.item_discount;
//             const total_before_gst = productToAdd.mrp_price - disc_cash; // Example: 20000
//             const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

//             // Calculate GST amount (percentage of total_before_gst)
//             const gst_amount = (total_before_gst * gst_tax) / 100;

//             // Calculate total after GST
//             const total_after_gst = total_before_gst + gst_amount;
//             state.cartProduct = [
//               {
//                 ...productToAdd,
//                 price: productToAdd.mrp_price,
//                 quantity: 1,
//                 discount_cash: disc_cash,
//                 used_disc_cash: disc_cash,
//                 mrp_price: productToAdd.mrp_price - disc_cash,
//                 fixed_price: productToAdd.mrp_price,
//                 fixed_item_discount: productToAdd.item_discount,
//                 total_after_gst: total_after_gst,
//               },
//               ...state.cartProduct,
//             ];
//           }
//         }
//       }
//     },
//     removeCart: (state, action) => {
//       const productToRemove = action.payload;

//       const index = state.cartProduct.findIndex(
//         (product) => product.id == productToRemove.id
//       );
//       if (index !== -1) {
//         if (state.cartProduct[index].quantity > 1) {
//           // Decrease the quantity by 1
//           state.cartProduct[index].quantity--;
//         } else {
//           // Quantity is 1, remove the product from the cart
//           state.cartProduct.splice(index, 1);
//         }
//       }
//     },
//     removeCart_product: (state, action) => {
//       const productToRemove = action.payload;
//       const index = state.cartProduct.findIndex(
//         (product) => product.id === productToRemove.id
//       );
//       if (index !== -1) {
//         // Quantity is 1, remove the product from the cart
//         state.cartProduct.splice(index, 1);
//       }
//     },
//     clearAllCart: (state, action) => {
//       state.cartProduct = [];
//       state.coupon_disc = 0;
//       state.coupon_code = "";
//       state.api_coupon_disc = 0;
//       state.api_coupon_code = "";
//     },
//     concat_cart: (state, action) => {
//       state.cartProduct = [...state.cartProduct, ...action.payload];
//       return;
//     },
//     removeconcat_cart: (state, action) => {
//       const productToRemove = action.payload;
//       if (productToRemove.return_id) {
//         const index = state.cartProduct.findIndex(
//           (product) => product.return_id == productToRemove.return_id
//         );
//         if (index !== -1) {
//           // Quantity is 1, remove the product from the cart
//           state.cartProduct.splice(index, 1);
//         }
//       } else {
//         const index = state.cartProduct.findIndex(
//           (product) => product?.id == productToRemove.id
//         );
//         if (index !== -1) {
//           // Quantity is 1, remove the product from the cart
//           state.cartProduct.splice(index, 1);
//         }
//       }
//     },
//     insert_whole_cart: (state, action) => {
//       state.cartProduct = action.payload;
//     },
//     add_coupon: (state, action) => {
//       state.coupon_disc = action.payload;
//     },
//     add_api_coupon: (state, action) => {
//       state.api_coupon_disc = action.payload;
//     },
//     add_api_coupon_code: (state, action) => {
//       state.api_coupon_code = action.payload;
//     },
//     add_coupon_code: (state, action) => {
//       state.coupon_code = action.payload;
//     },
//     add_api_coupon_percentage_value: (state, action) => {
//       //  console.log('action.payload',action.payload);
//       state.percentage_value = action.payload;
//     },
//     add_offer_name: (state, action) => {
//       state.offer_name = action.payload;
//     },
//   },
// });
// export const {
//   add_api_coupon_percentage_value,
//   concat_cart,
//   addCart,
//   removeconcat_cart,
//   add_offer_name,
//   removeCart,
//   clearAllCart,
//   change_quantity,
//   removeCart_product,
//   insert_whole_cart,
//   add_coupon,
//   add_coupon_code,
//   add_api_coupon,
//   add_api_coupon_code,
//   expected_date,
//   expected_amount,
//   setHDExpectedamt,
//   setHDdate,
//   update_price,
//   setselectedCoupon
// } = cartSlice.actions;
// export default cartSlice.reducer;

//////////////////////////////////////////////////////////////////////////////////////


// const data = state.cartProduct[index]?.item_offer?.split("|");
// const indices = [];
// for (let i = 0; i < data.length; i += 2) {
//   indices.push(parseInt(data[i]));
// }
// const percentages = [];
// for (let i = 1; i < data.length; i += 2) {
//   percentages.push(parseFloat(data[i]).toFixed(2));
// }
// const disc_in_percetge =
//   productToAdd.quantity - 1 >=
//     state.cartProduct[index]?.percentages_dic?.length
//     ? state.cartProduct[index].percentages_dic[
//     state.cartProduct[index].percentages_dic.length - 1
//     ]
//     : state.cartProduct[index].percentages_dic[
//     productToAdd.quantity - 1
//     ];
// const disc_amt =
//   state.cartProduct[index]?.discount_type == "P"
//     ? (productToAdd?.mrp_price *
//       state.cartProduct[index]?.fixed_item_discount) /
//     100
//     : state.cartProduct[index]?.fixed_item_discount;
// const disc_cash =
//   (state.cartProduct[index].price * disc_in_percetge) / 100;

// state.cartProduct[index].quantity = productToAdd.quantity;
// state.cartProduct[index].discount_cash =
//   productToAdd.quantity * disc_amt;
// state.cartProduct[index].used_disc_cash =
//   productToAdd.quantity * disc_cash;
// state.cartProduct[index].mrp_price =
//   state.cartProduct[index].price - disc_cash;


////////////////////////////////////////////////////////////////////////////////
import OpenNotification from "utilities/ToastNotification";

const { createSlice } = require("@reduxjs/toolkit");
const InitialState = {
  cartProduct: [],
  coupon_disc: 0,
  coupon_code: "",
  api_coupon_disc: 0,
  api_coupon_code: "",
  offer_name: "",
  percentage_value: 0,
  expected_date: new Date(),
  expected_amount: 0,
  selected_coupon: [],
};
const cartSlice = createSlice({
  name: "customer",
  initialState: InitialState,
  reducers: {
    change_quantity: (state, action) => {
      const productToAdd = action.payload;
      const index = state.cartProduct.findIndex(
        (product) => product.id == productToAdd.id
      );
      if (index != -1) {

        // if (state.cartProduct[index]?.item_offer != null) {


        //   const data = state.cartProduct[index]?.item_offer?.split("|");
        //   const indices = [];
        //   for (let i = 0; i < data.length; i += 2) {
        //     indices.push(parseInt(data[i]));
        //   }

        //   const percentages = [];
        //   for (let i = 1; i < data.length; i += 2) {
        //     percentages.push(parseFloat(data[i]).toFixed(2));
        //   }

        //   const disc_in_percetge =
        //     productToAdd.quantity - 1 >= state.cartProduct[index]?.percentages_dic?.length
        //       ? state.cartProduct[index].percentages_dic[
        //       state.cartProduct[index].percentages_dic.length - 1
        //       ]
        //       : state.cartProduct[index].percentages_dic[productToAdd.quantity - 1];

        //   const disc_amt =
        //     state.cartProduct[index]?.discount_type == "P"
        //       ? (productToAdd?.mrp_price * state.cartProduct[index]?.fixed_item_discount) / 100
        //       : state.cartProduct[index]?.fixed_item_discount;

        //   const disc_cash = (state.cartProduct[index].price * disc_in_percetge) / 100;
        //   console.log('disc_cash', disc_cash);
        //   console.log('disc_amt', disc_amt);

        // const total_before_gst = state.cartProduct[index].price - disc_cash;
        // const gst_amount = (total_before_gst * state.cartProduct[index]?.gst_tax) / 100;
        // console.log('gst_amount', gst_amount);

        // const total_after_gst = total_before_gst + gst_amount;

        // state.cartProduct[index].quantity = productToAdd.quantity;
        // state.cartProduct[index].discount_cash = productToAdd.quantity * disc_amt;
        // state.cartProduct[index].used_disc_cash = productToAdd.quantity * disc_cash;
        // state.cartProduct[index].mrp_price = total_before_gst;
        // state.cartProduct[index].total_after_gst = total_after_gst;

        // } 
        // if (state.cartProduct[index]?.item_offer != null) {
        //   const data = state.cartProduct[index]?.item_offer?.split("|");
        //   let indices = [];
        //   for (let i = 0; i < data.length; i += 2) {
        //     indices.push(parseInt(data[i]));
        //   }

        //   let percentages = [];
        //   for (let i = 1; i < data.length; i += 2) {
        //     percentages.push(parseFloat(data[i]).toFixed(2));
        //   }
        //   console.log('productToAdd.quantity - 1', productToAdd.quantity - 1);
        //   console.log('state.cartProduct[index]?.percentages_dic?.length', state.cartProduct[index]?.percentages_dic?.length);
        //   const disc_in_percetge =
        //     productToAdd.quantity - 1 >= state.cartProduct[index]?.percentages_dic?.length-1
        //       ? state.cartProduct[index].percentages_dic[
        //       state.cartProduct[index].percentages_dic.length - 1
        //       ]
        //       : state.cartProduct[index].percentages_dic[state.cartProduct[index].quantity - 1];
        //   console.log('disc_in_percetge', disc_in_percetge);

        //   const disc_amt =
        //     state.cartProduct[index]?.discount_type === "P"
        //       ? (state.cartProduct[index]?.price * state.cartProduct[index]?.fixed_item_discount) / 100
        //       : state.cartProduct[index]?.fixed_item_discount;

        //   const disc_cash = (state.cartProduct[index].price * disc_in_percetge) / 100;

        //   const total_before_gst = state.cartProduct[index].price - disc_cash;
        //   const gst_amount = (total_before_gst * state.cartProduct[index]?.gst_tax) / 100;

        //   const total_after_gst = total_before_gst + gst_amount;

        // state.cartProduct[index].quantity = productToAdd.quantity;
        //   state.cartProduct[index].discount_cash = state.cartProduct[index].quantity * disc_amt;
        //   state.cartProduct[index].used_disc_cash = state.cartProduct[index].quantity * disc_cash;
        //   state.cartProduct[index].mrp_price = total_before_gst;
        //   state.cartProduct[index].total_after_gst = total_after_gst;
        // }
        // if (state.cartProduct[index]?.item_offer != null) {
        //   const data = state.cartProduct[index]?.item_offer?.split("|");
        //   const indices = [];
        //   for (let i = 0; i < data.length; i += 2) {
        //     indices.push(parseInt(data[i]));
        //   }
        //   const percentages = [];
        //   for (let i = 1; i < data.length; i += 2) {
        //     percentages.push(parseFloat(data[i]).toFixed(2));
        //   }
        //   const disc_in_percetge =
        //     productToAdd.quantity - 1 >=
        //       state.cartProduct[index]?.percentages_dic?.length
        //       ? state.cartProduct[index].percentages_dic[
        //       state.cartProduct[index].percentages_dic.length - 1
        //       ]
        //       : state.cartProduct[index].percentages_dic[
        //       productToAdd.quantity - 1
        //       ];
        //   console.log('disc_in_percetge', disc_in_percetge);

        //   const disc_amt =
        //     state.cartProduct[index]?.discount_type == "P"
        //       ? (productToAdd?.mrp_price *
        //         state.cartProduct[index]?.fixed_item_discount) /
        //       100
        //       : state.cartProduct[index]?.fixed_item_discount;
        //   console.log('disc_amt', disc_amt);

        //   const disc_cash =
        //     (state.cartProduct[index].price * disc_in_percetge) / 100;
        //   const total_before_gst = state.cartProduct[index].price - disc_cash;
        //   console.log('total_before_gst', total_before_gst);

        //   const gst_amount = (total_before_gst * state.cartProduct[index]?.gst_tax) / 100;
        //   console.log('gst_amount', gst_amount);


        //   const total_after_gst = total_before_gst + gst_amount;
        //   state.cartProduct[index].quantity = productToAdd.quantity;
        //   state.cartProduct[index].discount_cash =
        //     productToAdd.quantity * disc_amt;
        //   state.cartProduct[index].used_disc_cash =
        //     productToAdd.quantity * disc_cash;
        //   // state.cartProduct[index].mrp_price =
        //   //   state.cartProduct[index].price - disc_cash;
        //   state.cartProduct[index].total_after_gst = total_after_gst;
        // }

        if (state.cartProduct[index]?.item_offer != null) {
          const data = state.cartProduct[index]?.item_offer?.split("|");
          const indices = [];
          const percentages = [];

          // Parsing indices and percentages
          for (let i = 0; i < data.length; i += 2) {
            indices.push(parseInt(data[i]));
            percentages.push(parseFloat(data[i + 1]));
          }

          // Determine discount percentage based on quantity
          const quantity = productToAdd?.quantity;
          let disc_in_percentage;

          if (quantity > percentages.length) {
            // Use the last discount percentage if quantity exceeds array length
            disc_in_percentage = percentages[percentages.length - 1];
          } else {
            // Use the corresponding discount percentage
            disc_in_percentage = percentages[quantity - 1];
          }
          // console.log('disc_in_percentage', disc_in_percentage);

          // Calculate discount amount
          const disc_amt =
            state.cartProduct[index]?.discount_type === "P"
              ? (state.cartProduct[index]?.price * state.cartProduct[index]?.fixed_item_discount) / 100
              : state.cartProduct[index]?.fixed_item_discount;

          const disc_cash = (state.cartProduct[index].price * disc_in_percentage) / 100;

          // Calculate totals
          const total_before_gst = (state.cartProduct[index].price - disc_cash) * quantity;
          const gst_amount = (total_before_gst * state.cartProduct[index]?.gst_tax) / 100;
          const total_after_gst = total_before_gst + gst_amount;

          // Update cart product details
          state.cartProduct[index].quantity = productToAdd.quantity;
          state.cartProduct[index].discount_cash = quantity * disc_amt;
          state.cartProduct[index].used_disc_cash = quantity * disc_cash;
          state.cartProduct[index].mrp_price = state.cartProduct[index].price - disc_cash;
          state.cartProduct[index].total_after_gst = total_after_gst;
        }


        else {
          // const qty = parseInt(state.cartProduct[index].quantity) + 1;
          // const disc_cash = state.cartProduct[index]?.discount_type == 'P' ? (state.cartProduct[index].price * state.cartProduct[index].item_discount / 100) : state.cartProduct[index].item_discount;
          // state.cartProduct[index].quantity++;
          // state.cartProduct[index].discount_cash = qty * disc_cash
          // state.cartProduct[index].used_disc_cash = qty * disc_cash

          // state.cartProduct[index].mrp_price = (state.cartProduct[index].price - disc_cash)


          const qty = productToAdd.quantity;

          const disc_cash_test =
            state.cartProduct[index]?.discount_type == "P"
              ? (state.cartProduct[index].price *
                state.cartProduct[index].item_discount) /
              100
              : state.cartProduct[index].item_discount;

          const total_before_gst =
            (productToAdd.mrp_price - disc_cash_test) * qty;

          const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

          // Calculate GST amount (percentage of total_before_gst)
          const gst_amount = (total_before_gst * gst_tax) / 100;

          // Calculate total after GST
          const total_after_gst = total_before_gst + gst_amount;

          ////////////////////////////////////////////////////////////  

          const disc_cash =
            state.cartProduct[index]?.discount_type == "P"
              ? (state.cartProduct[index].price *
                state.cartProduct[index].item_discount) /
              100
              : state.cartProduct[index].item_discount;
          state.cartProduct[index].quantity = productToAdd.quantity;
          state.cartProduct[index].discount_cash =
            productToAdd.quantity * disc_cash;
          state.cartProduct[index].used_disc_cash =
            productToAdd.quantity * disc_cash;
          state.cartProduct[index].total_after_gst = total_after_gst;


          //////////////////////////////////////////////////////////


        }
      }
    },
    // update_price: (state, action) => {
    //   const productToAdd = action.payload;
    //   const index = state.cartProduct.findIndex(
    //     (product) => product.id == productToAdd.id
    //   );
    //   state.cartProduct[index].total_after_gst = productToAdd.total_after_gst;
    //   state.cartProduct[index].discount_cash = productToAdd.discount_cash;
    //   state.cartProduct[index].mrp_price = productToAdd.price - productToAdd.discount_cash;

    // },
    update_price: (state, action) => {
      const productToAdd = action.payload;
      const index = state.cartProduct.findIndex(
        (product) => product.id === productToAdd.id
      );

      if (index !== -1) { // Ensure the product exists in the cart
        state.cartProduct[index] = {
          ...state.cartProduct[index], // Preserve existing properties
          total_after_gst: productToAdd.total_after_gst,
          discount_cash: productToAdd.discount_cash,
          mrp_price: ((productToAdd?.price * productToAdd?.quantity) - productToAdd.discount_cash) / productToAdd?.quantity,
          price_edited: productToAdd.price_edited, // Add or update new property
        };
      }
    },

    setHDdate: (state, action) => {
      state.expected_date = action.payload;
    },
    setHDExpectedamt: (state, action) => {
      state.expected_amount = parseInt(action.payload);
    },
    setselectedCoupon: (state, action) => {
      state.selected_coupon = action.payload;
    },
    addCart: (state, action) => {
      const productToAdd = action.payload;
      const index = state.cartProduct.findIndex(
        (product) => product.id == productToAdd.id
      );

      if (index === -1) {
        // Product doesn't exist in cart, add it with quantity 1

        if (productToAdd?.item_offer != null) {
          const disc_amt =
            productToAdd?.discount_type == "P"
              ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
              : productToAdd.item_discount;

          const data = productToAdd?.item_offer?.split("|");
          const indices = [];
          for (let i = 0; i < data.length; i += 2) {
            indices.push(parseInt(data[i]));
          }

          // Extracting percentages
          const percentages = [];
          for (let i = 1; i < data.length; i += 2) {
            // percentages.push(parseInt(data[i]));
            percentages.push(parseFloat(data[i]).toFixed(2));
          }
          let price = parseFloat(productToAdd.mrp_price - disc_amt);
          const disc_cash = (price * percentages[0]) / 100;
          const total_before_gst =
            productToAdd.mrp_price - disc_amt - disc_cash; // Example: 20000
          const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

          // Calculate GST amount (percentage of total_before_gst)
          const gst_amount = (total_before_gst * gst_tax) / 100;

          // Calculate total after GST
          const total_after_gst = total_before_gst + gst_amount;
          state.cartProduct = [
            {
              ...productToAdd,
              price: productToAdd.mrp_price - disc_amt,
              quantity: 1,
              discount_cash: disc_amt,
              used_disc_cash: disc_cash,
              mrp_price: productToAdd.mrp_price - disc_amt - disc_cash,
              percentages_dic: percentages,
              item_discount: percentages[0],
              fixed_price: productToAdd.mrp_price,
              fixed_item_discount: productToAdd.item_discount,
              discount_type: productToAdd?.discount_type,
              total_after_gst: total_after_gst,
            },
            ...state.cartProduct,
          ];
        } else {
          const disc_cash =
            productToAdd?.discount_type == "P"
              ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
              : productToAdd.item_discount;
          const total_before_gst = productToAdd.mrp_price - disc_cash; // Example: 20000
          const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

          // Calculate GST amount (percentage of total_before_gst)
          const gst_amount = (total_before_gst * gst_tax) / 100;

          // Calculate total after GST
          const total_after_gst = total_before_gst + gst_amount;
          state.cartProduct = [
            {
              ...productToAdd,
              price: productToAdd.mrp_price,
              quantity: 1,
              discount_cash: disc_cash,
              used_disc_cash: disc_cash,
              mrp_price: productToAdd.mrp_price - disc_cash,
              fixed_price: productToAdd.mrp_price,
              fixed_item_discount: productToAdd.item_discount,
              total_after_gst: total_after_gst,
            },
            ...state.cartProduct,
          ];
        }
      } else {
        // Product already exists in cart, increase quantity by 1

        if (!state.cartProduct[index].selected_product) {
          if (
            parseInt(state.cartProduct[index].quantity) ==
            parseInt(state.cartProduct[index].stock_qty)
          ) {
            OpenNotification(
              "danger",
              <p>
                <span style={{ fontWeight: 600, color: "red" }}>
                  Out of stock!!
                </span>
                <br />
                <span>
                  Available Quantity: {state.cartProduct[index].stock_qty}
                </span>
              </p>
            );
          } else {
            if (productToAdd?.item_offer != null) {
              const data = productToAdd?.item_offer?.split("|");
              const indices = [];
              for (let i = 0; i < data.length; i += 2) {
                indices.push(parseInt(data[i]));
              }

              // Extracting percentages
              const percentages = [];
              for (let i = 1; i < data.length; i += 2) {
                // percentages.push(parseInt(data[i]));
                percentages.push(parseFloat(data[i]).toFixed(2));
              }

              const disc_amt =
                productToAdd?.discount_type == "P"
                  ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
                  : productToAdd.item_discount;
              // console.log('disc_amt', disc_amt);

              const qty = parseInt(state.cartProduct[index].quantity) + 1;
              const disc_in_percetge =
                qty - 1 >= state.cartProduct[index]?.percentages_dic?.length
                  ? state.cartProduct[index].percentages_dic[
                  state.cartProduct[index].percentages_dic.length - 1
                  ]
                  : state.cartProduct[index].percentages_dic[qty - 1];
              // console.log("disc_in_percetge", disc_in_percetge);

              const disc_cash =
                (state.cartProduct[index].price * disc_in_percetge) / 100;
              

              state.cartProduct[index].quantity++;
              state.cartProduct[index].discount_cash = qty * disc_amt;
              state.cartProduct[index].item_discount = disc_in_percetge;
              state.cartProduct[index].used_disc_cash = qty * disc_cash;
              const tt =
                state.cartProduct[index].fixed_price * qty -
                qty * state.cartProduct[index].fixed_item_discount;

              state.cartProduct[index].total_after_gst =
                tt -
                qty * disc_cash +
                ((tt - qty * disc_cash) * state.cartProduct[index].gst_tax) /
                100;

              state.cartProduct[index].mrp_price =
                state.cartProduct[index].price - disc_cash;
            } else {
              const qty = parseInt(state.cartProduct[index].quantity) + 1;

              const disc_cash =
                state.cartProduct[index]?.discount_type == "P"
                  ? (state.cartProduct[index].price *
                    state.cartProduct[index].item_discount) /
                  100
                  : state.cartProduct[index].item_discount;

              const total_before_gst =
                (productToAdd.mrp_price - disc_cash) * qty;

              const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

              // Calculate GST amount (percentage of total_before_gst)
              const gst_amount = (total_before_gst * gst_tax) / 100;

              // Calculate total after GST
              const total_after_gst = total_before_gst + gst_amount;
              state.cartProduct[index].quantity++;
              state.cartProduct[index].discount_cash = qty * disc_cash;
              state.cartProduct[index].used_disc_cash = qty * disc_cash;
              state.cartProduct[index].total_after_gst = total_after_gst;
              state.cartProduct[index].mrp_price =
                state.cartProduct[index].price - disc_cash;
            }
          }
        } else {
          if (productToAdd?.item_offer != null) {
            const data = productToAdd?.item_offer?.split("|");
            const indices = [];
            for (let i = 0; i < data.length; i += 2) {
              indices.push(parseInt(data[i]));
            }

            // Extracting percentages
            const percentages = [];
            for (let i = 1; i < data.length; i += 2) {
              // percentages.push(parseInt(data[i]));
              percentages.push(parseFloat(data[i]).toFixed(2));
            }

            const disc_cash = (productToAdd.mrp_price * percentages[0]) / 100;
            const total_before_gst = productToAdd.mrp_price - disc_cash; // Example: 20000
            const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

            // Calculate GST amount (percentage of total_before_gst)
            const gst_amount = (total_before_gst * gst_tax) / 100;

            // Calculate total after GST
            const total_after_gst = total_before_gst + gst_amount;
            state.cartProduct = [
              {
                ...productToAdd,
                price: productToAdd.mrp_price,
                quantity: 1,
                discount_cash: disc_cash,
                mrp_price: productToAdd.mrp_price - disc_cash,
                percentages_dic: percentages,
                item_discount: percentages[0],
                total_after_gst: total_after_gst,
                discount_type: "P",
              },
              ...state.cartProduct,
            ];
          } else {
            // const disc_cash = productToAdd?.discount_type == 'P' ? (productToAdd.mrp_price * productToAdd.item_discount / 100) : productToAdd.item_discount;
            // state.cartProduct = [
            //     {
            //         ...productToAdd,
            //         price: productToAdd.mrp_price,
            //         quantity: 1,
            //         discount_cash: disc_cash,
            //         mrp_price: productToAdd.mrp_price - disc_cash

            //     },
            //     ...state.cartProduct
            // ];
            const disc_cash =
              productToAdd?.discount_type == "P"
                ? (productToAdd.mrp_price * productToAdd.item_discount) / 100
                : productToAdd.item_discount;
            const total_before_gst = productToAdd.mrp_price - disc_cash; // Example: 20000
            const gst_tax = productToAdd?.gst_tax; // Example: 12 (12% GST)

            // Calculate GST amount (percentage of total_before_gst)
            const gst_amount = (total_before_gst * gst_tax) / 100;

            // Calculate total after GST
            const total_after_gst = total_before_gst + gst_amount;
            state.cartProduct = [
              {
                ...productToAdd,
                price: productToAdd.mrp_price,
                quantity: 1,
                discount_cash: disc_cash,
                used_disc_cash: disc_cash,
                mrp_price: productToAdd.mrp_price - disc_cash,
                fixed_price: productToAdd.mrp_price,
                fixed_item_discount: productToAdd.item_discount,
                total_after_gst: total_after_gst,
              },
              ...state.cartProduct,
            ];
          }
        }
      }
    },
    removeCart: (state, action) => {
      const productToRemove = action.payload;

      const index = state.cartProduct.findIndex(
        (product) => product.id == productToRemove.id
      );
      if (index !== -1) {
        if (state.cartProduct[index].quantity > 1) {
          // Decrease the quantity by 1
          state.cartProduct[index].quantity--;
        } else {
          // Quantity is 1, remove the product from the cart
          state.cartProduct.splice(index, 1);
        }
      }
    },
    removeCart_product: (state, action) => {
      const productToRemove = action.payload;
      const index = state.cartProduct.findIndex(
        (product) => product.id === productToRemove.id
      );
      if (index !== -1) {
        // Quantity is 1, remove the product from the cart
        state.cartProduct.splice(index, 1);
      }
    },
    clearAllCart: (state, action) => {
      state.cartProduct = [];
      state.coupon_disc = 0;
      state.coupon_code = "";
      state.api_coupon_disc = 0;
      state.api_coupon_code = "";
    },
    concat_cart: (state, action) => {
      state.cartProduct = [...state.cartProduct, ...action.payload];
      return;
    },
    removeconcat_cart: (state, action) => {
      const productToRemove = action.payload;
      if (!productToRemove?.return_id) {
        const index = state.cartProduct.findIndex(
          (product) => product.id == productToRemove.id
        );
        if (index !== -1) {
          // Quantity is 1, remove the product from the cart
          state.cartProduct.splice(index, 1);
        }
      } else {
        const index = state.cartProduct.findIndex(
          (product) => product.return_id == productToRemove.return_id
        );
        if (index !== -1) {
          // Quantity is 1, remove the product from the cart
          state.cartProduct.splice(index, 1);
        }
      }
    },
    insert_whole_cart: (state, action) => {
      state.cartProduct = action.payload;
    },
    add_coupon: (state, action) => {
      state.coupon_disc = action.payload;
    },
    add_api_coupon: (state, action) => {
      state.api_coupon_disc = action.payload;
    },
    add_api_coupon_code: (state, action) => {
      state.api_coupon_code = action.payload;
    },
    add_coupon_code: (state, action) => {
      state.coupon_code = action.payload;
    },
    add_api_coupon_percentage_value: (state, action) => {
      //  console.log('action.payload',action.payload);
      state.percentage_value = action.payload;
    },
    add_offer_name: (state, action) => {
      state.offer_name = action.payload;
    },
  },
});
export const {
  add_api_coupon_percentage_value,
  concat_cart,
  addCart,
  removeconcat_cart,
  add_offer_name,
  removeCart,
  clearAllCart,
  change_quantity,
  removeCart_product,
  insert_whole_cart,
  add_coupon,
  add_coupon_code,
  add_api_coupon,
  add_api_coupon_code,
  expected_date,
  expected_amount,
  setHDExpectedamt,
  setHDdate,
  update_price,
  setselectedCoupon,
} = cartSlice.actions;
export default cartSlice.reducer;